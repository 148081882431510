<template>
  <main>
    <sqr-error :error="loadError" />
    <sqr-error :error="accountsLoadError" />
    <section class="section">
      <div class="container">
        <div class="columns is-mobile">
          <div class="column is-half">
            <sqr-progress v-show="loading" />
            <sqr-error-note :error="sendError" />
            <div v-if="record">
              <div class="field">
                <sqr-input-text
                  label="org_name"
                  :value="record.name"
                  size="medium"
                />
              </div>
              <draggable
                :value="content"
                @input="contentSet($event)"
                :options="{ group: 'org' }"
              >
                <div
                  v-for="entry in content"
                  :key="entry.id"
                  class="shadow border-t bg-white"
                >
                  <div v-if="entry.type === 'account'" class="px-2 py-1">
                    <div class="flex justify-between">
                      <div class="flex items-center">
                        <div class="">
                          <span class="icon"
                            ><fa :icon="['fal', 'arrows']" fixed-width
                          /></span>
                        </div>
                        <div class="px-2">
                          <account-name :account="entry" />
                        </div>
                      </div>
                      <div class="flex items-center">
                        <div class="pl-2">
                          <sqr-button
                            icon="times"
                            tooltip="buttons_remove"
                            @click="itemRemove(entry.id)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="entry.type === 'team'" class="px-2 py-1">
                    <div class="flex justify-between">
                      <div class="flex items-center">
                        <div class="">
                          <span class="icon">
                            <fa :icon="['fal', 'arrows']" fixed-width />
                          </span>
                        </div>
                        <div class="px-2">
                          <sqr-input-text
                            :value="entry.name"
                            @change="teamSet(entry.id, 'name', $event)"
                          />
                        </div>
                      </div>
                      <sqr-button
                        icon="times"
                        tooltip="buttons_remove"
                        @click="itemRemove(entry.id)"
                      />
                    </div>
                  </div>
                </div>
                <div class="dropzone" v-if="content.length === 0">
                  {{ $t('org_dropzone') }}
                </div>
              </draggable>
            </div>
            <div style="margin-top: 1rem">
              <sqr-button icon="plus" label="org_team_add" @click="teamAdd" />
            </div>
          </div>
          <div class="column is-half">
            <sqr-progress v-show="accountsLoading" />
            <div class="available">
              <div class="scroll">
                <div>
                  <div class="dropzone mb-2">
                    {{ $t('org_dropzone_remove') }}
                  </div>
                  <draggable
                    :value="available"
                    draggable=".enabled"
                    :options="{ group: 'org' }"
                  >
                    <div
                      v-for="account in available"
                      :key="account.id"
                      class="shadow border-t bg-white"
                      :class="{
                        'text-gray-500': usedIds[account.id],
                        enabled: !usedIds[account.id]
                      }"
                    >
                      <div>
                        <div class="flex justify-between">
                          <div class="flex items-center py-2">
                            <div class="px-2">
                              <fa :icon="['fal', 'arrows']" fixed-width />
                            </div>
                            <account-name :account="account" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
              <sqr-fire-page
                class="p-1"
                v-bind="pagination"
                hide-sizes
                @page-set="accountPageSet"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style lang="postcss" scoped>
.dropzone {
  border: 1px dashed lightgray;
  padding: 1rem;
}
.available {
  position: sticky;
  top: 3.5em;
}
.available .scroll {
  max-height: 80vh;
  overflow-y: auto;
  padding: 2px 2px;
}
</style>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { fromPairs, pick, omit } from 'ramda';
import Draggable from 'vuedraggable';
import company from './company';
import SqrProgress from '@/sqrd/SqrProgress';
import SqrError from '@/sqrd/SqrError';
import SqrErrorNote from '@/sqrd/SqrErrorNote';
import SqrInputText from '@/sqrd/SqrInputText';
import SqrButton from '@/sqrd/SqrButton';
import SqrFirePage from '@/sqrd/SqrFirePage';
import AccountName from '@/components/AccountName';
import nanourl from '@/utils/nanourl';
export default {
  name: 'Org',
  mixins: [company],
  components: {
    Draggable,
    SqrProgress,
    SqrError,
    SqrErrorNote,
    SqrInputText,
    SqrButton,
    SqrFirePage,
    AccountName
  },
  props: {
    oid: String
  },
  computed: {
    ...mapState('org', [
      'record',
      'updates',
      'loading',
      'loadError',
      // 'saving',
      'sendError'
    ]),
    ...mapState('accounts', {
      accounts: 'records',
      accountsLoading: 'loading',
      accountsLoadError: 'loadError'
    }),
    ...mapGetters('accounts', ['pagination']),
    content() {
      return this.record?.content;
    },
    available() {
      return (
        this.accounts?.map(item => ({
          ...pick(['givenName', 'familyName', 'email', 'id'], item),
          type: 'account'
        })) ?? []
      );
    },
    usedIds() {
      return fromPairs(
        this.record?.content
          ?.filter(entry => entry.type === 'account')
          ?.map(entry => [entry.id, true]) ?? []
      );
    }
  },
  mounted() {
    this.sub({
      path: ['companies', this.cid, 'orgs'],
      id: this.oid
    });
    this.accountsSub({
      path: ['companies', this.cid, 'accounts'],
      // where: [['archived', '==', false]],
      orderBy: [['nameSort', 'asc']]
    });
  },
  async beforeRouteLeave(to, from, next) {
    try {
      await this.send({ clear: true });
      next();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  methods: {
    ...mapActions('org', ['sub', 'fieldSet', 'send']),
    ...mapActions('accounts', {
      accountsSub: 'sub',
      accountPageSet: 'pageSet'
    }),
    teamAdd() {
      const id = nanourl(5);
      const value = [...this.record?.content, { id, type: 'team', name: '' }];
      this.fieldSet({ field: 'content', value });
    },
    teamSet(id, field, value) {
      const content = JSON.parse(JSON.stringify(this.record.content));
      const entry = content.find(
        entry => entry.id === id && entry.type === 'team'
      );
      this.$set(entry, field, value);
      this.fieldSet({ field: 'content', value: content });
    },
    itemSet(id, field, value) {
      const content = JSON.parse(JSON.stringify(this.record.content));
      const entry = content.find(entry => entry.id === id);
      this.$set(entry, field, value);
      this.fieldSet({ field: 'content', value: content });
    },
    itemRemove(id) {
      const value = [...this.record.content];
      const index = value.findIndex(item => item.id === id);
      value.splice(index, 1);
      this.fieldSet({ field: 'content', value });
    },
    contentSet(content) {
      let parentId = undefined;
      const value = content.map(item => {
        if (item.type === 'team') {
          parentId = item.id;
          return item;
        } else if (item.type === 'account') {
          return parentId ? { ...item, parentId } : omit(['parentId'], item);
        }
        return item;
      });
      this.fieldSet({ field: 'content', value });
    }
  }
};
</script>
